import React from "react";
import Layout from "../../layouts/layout";
import Banner from "../../components/banner";
import Frame from "../../components/frame";
import SEO from "../../components/seo";


const Applications = () => {
  return (
    <>
    <SEO title="Applications" metaDesc="Fire Suppression And Insulation Preventing Air & Gas Leakage, Void Filling - Roof Support, Goaf Sealing - Back Filling, Water Control, Strata Control, Improving Stability" canonical="/applications" />
      <Layout>



      <Banner title="Applications" titleBlue="" imageUrl="/assets/images/randd.png" imageBool={false} />


      <Frame
          imageUrl=""
          imageAlt=""
          reverse={false}
          titleWhite=""
          titleBlue="Fire Suppression And Insulation Preventing Air & Gas Leakage"
          description="Narofleks™️ Phenolic Foam Offers A Reliable Solution For Fire Suppression, Insulation, And Preventing Air And Gas Leakage In Underground Coal Mines. Fire-Resistant Properties, Excellent Insulation Capabilities, And Ability To Create Airtight Seals Make Narofleks™️ An Essential Component In Enhancing Mine Safety And Efficiency."
          buttonLink="/fire-suppression-and-insulation-preventing-air-and-gas-leakage"
          buttonText="More"
          number="1"
          animationUrl="/assets/animation/baraj_yapimi_02.mp4"
        />
        <Frame
          imageUrl=""
          imageAlt=""
          reverse={true}
          titleWhite=""
          titleBlue="Void Filling - Roof Support"
          description="Narofleks™️ Phenolic Foam Is Increasingly Being Used For Void Filling And Roof Support In Underground Mines And Tunnels Due To Its Unique Properties Such As Fire Resistance, Thermal Insulation, And Structural Integrity"
          titleWhiteTwo=""
          titleBlueTwo="Goaf Sealing - Back Filling"
          descriptionTwo="Goaf Sealing And Backfilling Are Crucial Processes In Underground Coal Mining That Aim To Enhance Safety, Prevent Air And Gas Leaks, And Maintain Structural Stability. These Techniques Are Integral To Effective Mine Management And Environmental Protection."
          buttonLink="/void-filling-roof-support-goaf-sealing-back-filling"
          buttonText="More"
          number="2"
          animationUrl="/assets/animation/gocuk_izolasyonu_06.mp4"
        />
          <frame
          imageUrl=""
          imageAlt=""
          reverse={false}
          titleWhite=""
          titleBlue="Water Control"
          description="Water Control Is A Critical Aspect Of Maintaining Safety And Operational Efficiency In Underground Mines And Tunnels. Injected Chemicals Petry-N™️ Play A Vital Role In Managing Groundwater Ingress, Stabilizing The Surrounding Rock And Soil, And Preventing Water-Related Issues"
          buttonLink="/water-control"
          buttonText="More"
          number="3"
          animationUrl="/assets/animation/enjeksiyon_03.mp4"
        />
        <Frame
          imageUrl=""
          imageAlt=""
          reverse={true}
          titleWhite=""
          titleBlue="Strata Control"
          description="Petry-N™️ Is An Effective Solution For Strata Control In Underground Mines And Tunnels, Offering High Strength, Flexibility, And Rapid Curing. Its Ability To Reinforce Rock Masses, Provide Protective Coatings, And Control Water Ingress Makes Petry-N™️ A Valuable Tool For Maintaining Stability And Safety In Subterranean Environments."
          titleWhiteTwo=""
          titleBlueTwo="Improving Stability"
          descriptionTwo="Petry-N™️ Is A Hybrid Material Combining The Properties Of Polyurea And Silicate, Offering Significant Benefits For Improving Stability In Underground Mines And Tunnels. This Advanced Material Is Known For Its Strength, Flexibility, And Rapid Curing Time, Making Petry-N™️ An Ideal Choice For Reinforcing And Stabilizing Subterranean Structures."
          buttonLink="/strata-control-improving-stability"
          buttonText="More"
          number="4"
          animationUrl="/assets/animation/bosluklarin_doldurulmasi_02.mp4"
        />

      </Layout>
    </>
  );
};

export default Applications;
